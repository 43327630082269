import { ButtonIcon, MediaImage, WebMenuSettingsSvg } from '@dce-front/dive';
import type { NavigationInfoItem } from '@dce-front/hodor-types/modules/account_profile/definitions';
import { useMemo, type JSX } from 'react';
import Linker from '../../Linker/Linker';
import type { LinkerSettings } from '../../Linker/types';

type HeaderSettingsButtonOneDiscoveryProps = {
  /**
   * Whether or not the button is selected and represents current path.
   * @default false
   */
  isSelected?: boolean;
  /**
   * User menu button data.
   */
  data?: NavigationInfoItem;
};

/**
 * Header settings button for OneDiscovery. It renders the settings button with a custom icon.
 * Used for GLine user menu.
 * @example
 *   <HeaderSettingsButtonOneDiscovery
 *     data={item}
 *     isSelected={isActive}
 *   />
 */
export function HeaderSettingsButtonOneDiscovery({
  isSelected = false,
  data,
}: HeaderSettingsButtonOneDiscoveryProps): JSX.Element | null {
  const { onClick, URLImageForDarkMode } = data || {};

  // Prioritize the URLImageForDarkMode over the default Dive icon
  const icon = useMemo(
    () =>
      URLImageForDarkMode ? (
        <MediaImage
          height="auto"
          width="auto"
          src={URLImageForDarkMode}
          alt={onClick?.displayName}
        />
      ) : (
        <WebMenuSettingsSvg />
      ),
    [URLImageForDarkMode, onClick?.displayName],
  );

  if (!$_BUILD_RENDERMODE_CSR) {
    return null;
  }

  return (
    <Linker
      data={onClick ? { mainOnClick: onClick } : undefined}
      renderWrapper={(linkerSettings: LinkerSettings) => (
        <ButtonIcon
          aria-label="Settings"
          icon={icon}
          isSelected={isSelected}
          variant="header"
          onClick={linkerSettings.onClick}
        />
      )}
    />
  );
}
