import type { ApiV2DetailV5 } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import { Template } from '@dce-front/sdk-hodor';

// We only accept a limited number of Template to avoid unexpected behavior
const WHITE_LIST_TABS_DISPLAYTEMPLATES: Template[] = [
  Template.ContentGrid,
  Template.EpisodesList,
  Template.Highlights,
  Template.Landing,
  Template.MoreInfos,
  Template.SportRanking,
  Template.SportVitrine,
];

export const getDetailV5Formatted = ({
  data,
  isFeatDetailTabHighlights,
  isFeatDetailTabStatistics,
  isFeatDetailTabRanking,
  isFeatDetailTabRating,
  isFeatDetailTabTimeline,
  isFeatShortVideoList,
}: {
  data: ApiV2DetailV5;
  isFeatDetailTabHighlights: boolean;
  isFeatDetailTabStatistics: boolean;
  isFeatDetailTabRanking: boolean;
  isFeatDetailTabTimeline: boolean;
  isFeatDetailTabRating: boolean;
  isFeatShortVideoList: boolean;
}): ApiV2DetailV5 => {
  const {
    tabs = [],
    detail: { moreInfos },
  } = data;

  if (!tabs.length && !moreInfos) {
    return data;
  }

  const isMoreInfosAlreadyAdded = tabs.some(
    ({ displayTemplate }) => displayTemplate === Template.MoreInfos,
  );

  if (!moreInfos || isMoreInfosAlreadyAdded) {
    return data;
  }

  // Remove label as it is unused by & non-compliant with ApiV2DetailTab
  const { label, ...rest } = moreInfos;

  let filterTabs = tabs.filter(
    (tab) =>
      tab.displayTemplate &&
      WHITE_LIST_TABS_DISPLAYTEMPLATES.includes(
        tab.displayTemplate as Template,
      ),
  );

  if (!isFeatDetailTabHighlights || !isFeatShortVideoList) {
    filterTabs = filterTabs.filter(
      (tab) => tab.displayTemplate !== Template.Highlights,
    );
  }

  if (!isFeatDetailTabRanking) {
    filterTabs = filterTabs.filter(
      (tab) => tab.displayTemplate !== Template.SportRanking,
    );
  }

  if (
    !isFeatDetailTabTimeline &&
    !isFeatDetailTabStatistics &&
    !isFeatDetailTabRating
  ) {
    filterTabs = filterTabs.filter(
      (tab) => tab.displayTemplate !== Template.SportVitrine,
    );
  }

  return {
    ...data,
    // manually adds MoreInfos tab if absent from `tabs`
    tabs: [...filterTabs, { ...rest, displayName: label }],
  };
};
