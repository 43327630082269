import { Avatar } from '@dce-front/dive';
import type { ApiV2AvatarsV2Strate } from '@dce-front/hodor-types/api/v2/avatars/v2/definitions';
import { Binder } from '@dce-front/one-navigation';
import { DIMENSIONS } from '@dce-front/onewebapp-dive-utils';
import { ProfileModal } from '@dce-front/onewebapp-utils';
import { HorizontalList } from '@dce-front/sharedcomponent';
import type { JSX } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import {
  MIDDLEWARE_MODAL,
  MIDDLEWARE_MODAL_TOP,
} from '../../../helpers/oneNavigation/middleware';
import { useTranslation } from '../../../lang';
import { setAvatar, setProfileView } from '../../../store/slices/profilesModal';
import {
  modalIsFromLandingSelector,
  selectedProfileSelector,
} from '../../../store/slices/profilesModal-selectors';
import ThumborMediaImage from '../../ThumborMediaImage/ThumborMediaImage';
import styles from './AvatarV2Selection.css';

type AvatarV2SelectionProps = {
  avatarStrates: ApiV2AvatarsV2Strate[];
};

function AvatarV2Selection({
  avatarStrates,
}: AvatarV2SelectionProps): JSX.Element {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const profileSelected = useSelector(selectedProfileSelector);
  const modalIsFromLanding = useSelector(modalIsFromLandingSelector);

  const handleClick = (avatarUrl: string) => {
    const creationView = modalIsFromLanding
      ? ProfileModal.ProfileCreationViewFromLanding
      : ProfileModal.ProfileCreationView;
    const profileModalView = profileSelected
      ? ProfileModal.ProfileEditionView
      : creationView;

    dispatch(setAvatar(avatarUrl));
    dispatch(setProfileView(profileModalView));
  };

  return (
    <div className={styles.avatarV2Selection}>
      <h2 className={styles.avatarV2Selection__title}>
        {t('ProfileManager.chooseAvatar')}
      </h2>
      {avatarStrates.map((strate, i) => {
        const isFirstStrate = i === 0;

        return (
          <div key={strate.title} className={styles.avatarV2Selection__strate}>
            <span className={styles.avatarV2Selection__strate__title}>
              {strate.title}
            </span>

            <Binder
              middleware={
                isFirstStrate ? MIDDLEWARE_MODAL_TOP : MIDDLEWARE_MODAL
              }
            >
              <HorizontalList
                showControls={!$_BUILD_RENDERMODE_CSR}
                className={styles.avatarV2Selection__strate__list}
              >
                {strate.contents?.map(
                  ({ altImage = 'avatar', URLImage, avatarID }) => {
                    const avatarIdLabel = `avatar_${avatarID}`;

                    if (!URLImage) {
                      return;
                    }

                    return (
                      <Avatar
                        key={avatarIdLabel}
                        data-testid={avatarIdLabel}
                        aria-label={altImage}
                        onClick={() => handleClick(URLImage)}
                        image={
                          <ThumborMediaImage
                            url={URLImage}
                            urlMobile={URLImage}
                            dimensions={DIMENSIONS.PROFILE_AVATAR.extraLarge}
                          />
                        }
                        className={styles.avatarV2Selection__strate__avatar}
                      />
                    );
                  },
                )}
              </HorizontalList>
            </Binder>
          </div>
        );
      })}
    </div>
  );
}

export default AvatarV2Selection;
