// Layout
export const HEADER_LAYOUT_SHIFT_BP = 700; // Header shifts layout at 700px
export const HEADER_HEIGHT_TABLET = 80;
export const HEADER_HEIGHT_MOBILE_SEARCH_OPEN = 66;

export const DATA_ATTR_HEADER_NAVIGATION_WRAPPER = 'data-header-navigation';
export const DATA_ATTR_HEADER_SEARCH_TRIGGER = 'data-header-search-trigger';

// Themes
export const HeaderTheme = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  TRANSPARENT: 'TRANSPARENT',
} as const;

export type HeaderTheme = (typeof HeaderTheme)[keyof typeof HeaderTheme];

export const DEFAULT_HEADER_THEME = HeaderTheme.LIGHT;
export const HEADER_TRANSPARENCY_SCROLL_DEPTH_LIMIT = 125; /* After this page scroll depth, transparency no longer applies */

// Search
export const HEADER_SEARCH_ICON_SIZE = 24; // (css equivalent: --size_header-search-icon. Do not update one without updating the other !)

export const HEADER_SEARCH_INPUT_EXPANSION_DURATION = 250; // (css equivalent --duration_header-search-expansion)

export const HEADER_SEARCH_INPUT_HEIGHT = 48; // (css equivalent: --size_header-search-input-height)

const HEADER_SEARCH_INPUT_ICON_DISTANCE_FROM_EDGE = 14; // How far the search icon and close button are from the left/right edges  (css equivalent: --size_header-search-input-icon-distance-from-edge)
export const HEADER_SEARCH_INPUT_EXTENSION =
  HEADER_SEARCH_INPUT_ICON_DISTANCE_FROM_EDGE; // How much wider is the initial search form on each side compared to the trigger.

export const HEADER_SEARCH_INPUT_MIN_EDGE_DISTANCE = 5; // How close to the edge of the screen the form is allowed to be

export const HEADER_ID = 'header';
