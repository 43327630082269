import type { AcmErrorCode } from '@dce-front/types-acm';
import { isAcmApiError } from './isAcmApiError';

/** Get errorCode from error */
export const getErrorCode = (error: unknown): AcmErrorCode | undefined => {
  if (isAcmApiError(error)) {
    return error.errorCode;
  }

  return undefined;
};
