import type { ApiV2Authenticate } from '@dce-front/hodor-types/api/v2/authenticate/definitions';
import type { ApiV2PageSectionsList } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/sections_list';
import type { HodorSdkConfig } from '@dce-front/sdk-hodor';
import {
  getHodorApiParametersFromBranSdkOnClickParameters,
  requestPage,
} from '@dce-front/sdk-hodor';
import { markPerformance } from '../../helpers/performance/markPerformance';
import type { IState } from '../../store/types/State-type';
import { NavigationItemType } from '../../typings/hodor';

export type GetUserMenuExtendedParameters = {
  sdkConfig: HodorSdkConfig;
  state: IState;
  userMenu: ApiV2Authenticate['userMenu'];
};

export async function getUserMenuExtended({
  sdkConfig,
  state,
  userMenu = [],
}: GetUserMenuExtendedParameters): Promise<ApiV2PageSectionsList | undefined> {
  const { onClick, parameters } =
    userMenu.find(
      (entry) => entry.type === NavigationItemType.CurrentProfile,
    ) || {};
  const { URLPage } = onClick || {};

  if (URLPage) {
    const hodorApiParameters =
      getHodorApiParametersFromBranSdkOnClickParameters(parameters);

    const userMenuExtended = await requestPage(sdkConfig, {
      url: URLPage,
      ...(hodorApiParameters.length && { parameters: hodorApiParameters }),
    });

    markPerformance('hodor-requestpage-fetched', state);

    return userMenuExtended;
  }

  return undefined;
}
