import type { PlayerInstance } from '../../../store/slices/player';
import { getFinalPlayerSettings } from './getFinalPlayerSettings';
import type { CommonProps } from './types';

export type UpdatePlayerSettingsType = CommonProps & {
  playerInstance: PlayerInstance;
};

export function updatePlayerSettings({
  adToken,
  settings,
  credentials,
  dispatch,
  enableAd,
  isBlueTim,
  isFeatDidomi,
  locale,
  offerLocation,
  offerZone,
  onKillPlayer,
  playerContainerElement,
  playerInstance,
  targetedAds,
  consentString,
}: UpdatePlayerSettingsType): void {
  const { credentials: finalCredentials, settings: finalSettings } =
    getFinalPlayerSettings({
      baseSettings: settings,
      credentials,
      dispatch,
      enableAd,
      isBlueTim,
      locale,
      offerLocation,
      offerZone,
      onKillPlayer,
    });

  if (!$_BUILD_RENDERMODE_CSR) {
    playerInstance.setAdData({
      gdpr: 1,
      targetedAds,
      ...(isFeatDidomi && consentString && { consentString }),
      // conditionally add adToken to the adData object depending on the value of targetedAds and isFeatDidomi
      ...(((isFeatDidomi && adToken) || (adToken && targetedAds === '1')) && {
        targeting: adToken,
      }),
    });
    playerInstance.loadVideo(
      finalSettings,
      finalCredentials,
      playerContainerElement,
    );
  }
}
