import { OfferLocation } from '@dce-front/sdk-core';
import type { VirtualKeyboardProps } from '@dce-front/sharedcomponent';
import { VirtualKeyboard as VirtualKeyboardLib } from '@dce-front/sharedcomponent';
import type { JSX } from 'react';
import { useInvariantSelector } from '../../helpers/hooks/useInvariantSelector';
import { offerLocationSelector } from '../../store/slices/application-selectors';

export function VirtualKeyboard(
  keyboardProps: VirtualKeyboardProps,
): JSX.Element {
  const offerLocation = useInvariantSelector(offerLocationSelector);
  const isPolish = offerLocation === OfferLocation.pl;
  const layout = isPolish ? ['polish', 'big'] : 'big';

  return (
    <VirtualKeyboardLib
      isPolish={isPolish}
      layout={layout}
      {...keyboardProps}
    />
  );
}

export {
  virtualKeyboardContext,
  VirtualKeyboardProvider,
} from '@dce-front/sharedcomponent';
