import classNames from 'classnames';
import type { JSX } from 'react';
import { TemplateTypes } from '../../constants/templateTypes';
import type { ButtonProps } from '../Button/Button';
import { BIG_LABEL_MINIMUN_LENGTH } from '../Button/Button';
import buttonStyles from '../Button/Button.css';
import Linker from '../Linker/Linker';
import type { LinkerData } from '../Linker/types';
import Spinner from '../Spinner/Spinner';
import styles from './ButtonLinker.css';

export const ButtonLinkerVariant = {
  PrimaryAction: 'primaryaction',
  MenuOption: 'menuoption',
} as const;

export type ButtonLinkerVariant =
  (typeof ButtonLinkerVariant)[keyof typeof ButtonLinkerVariant];

export type ButtonLinkerProps = ButtonProps & {
  linkerData?: LinkerData;
  role?: string;
  variant?: ButtonLinkerVariant;
};

/**
 * ButtonLinker
 * It's a Linker with the appearance of Button
 *
 * @param className     Custom className to apply
 * @param text          Text to display
 * @param color         Theme of the button, see prop types for all supported colors
 * @param isMobile      whether we are on mobile or not
 * @param isDisabled    whether to enable/disable JS events and add styles
 * @param fullWidth     If true, the button will take up the full width of its container
 * @param shape         Shape of the button
 * @param icon          Icon of the button
 */

function ButtonLinker({
  ariaLabel,
  bigLabelMinimumLength = BIG_LABEL_MINIMUN_LENGTH,
  buttonRef,
  className = '',
  color = TemplateTypes.PRIMARY,
  fullWidth = false,
  hasActionIcon = false,
  icon,
  id,
  isDisabled = false,
  isLoading = false,
  isMobile = false,
  isV5Style,
  linkerData,
  role = 'link',
  shape,
  shouldHandleBigLabel,
  subtext,
  text,
  variant = ButtonLinkerVariant.PrimaryAction,
}: ButtonLinkerProps): JSX.Element {
  const getClassnames = () =>
    classNames(buttonStyles.button, [buttonStyles[`button__${color}`]!], {
      [buttonStyles['button--mobile']!]: isMobile,
      [buttonStyles['button--fullWidth']!]: fullWidth,
      [buttonStyles['button--isV5Style']!]: isV5Style,
      [buttonStyles[`button__${shape || ''}`]!]: shape,
      globalButton: isV5Style,
      [className]: className,
    });

  const hasActionGroup = hasActionIcon && subtext;

  return (
    <Linker
      ariaLabel={ariaLabel || text}
      className={getClassnames()}
      data={linkerData}
      disabled={isDisabled}
      id={id}
      ref={buttonRef}
      role={role}
      title={text}
    >
      <div
        className={classNames(styles.buttonLinker, {
          [styles['buttonLinker--menu-option']!]:
            variant === ButtonLinkerVariant.MenuOption,
        })}
      >
        {isLoading ? (
          <Spinner size={5} isUnsetPosition color="white" />
        ) : (
          <>
            {!hasActionIcon && icon && (
              <span className={buttonStyles.button__icon}>{icon}</span>
            )}
            {text && (
              <span
                className={classNames(buttonStyles.button__label, {
                  [buttonStyles['button__label--big']!]:
                    shouldHandleBigLabel &&
                    text.length >= bigLabelMinimumLength,
                })}
              >
                {text}
              </span>
            )}
            {hasActionGroup && (
              <span className={buttonStyles.button__actionGroup}>
                <span className={buttonStyles.button__subtext}>{subtext}</span>
                <span className={buttonStyles.button__icon}>{icon}</span>
              </span>
            )}
            {!hasActionGroup && subtext && (
              <span className={buttonStyles.button__subtext}>{subtext}</span>
            )}
            {!hasActionGroup && hasActionIcon && icon && (
              <span className={buttonStyles.button__icon}>{icon}</span>
            )}
          </>
        )}
      </div>
    </Linker>
  );
}

export default ButtonLinker;
