import type { AriaAttributes, HTMLAttributes, ReactElement } from 'react';
import type { DiveBackgroundVariant, DiveProps } from '../../types/Dive.types';
import type { ValueOf } from 'ts-essentials';

export const MediaAspectRatio = {
  Portrait4x7: '47',
  Portrait3x5: '35',
  Portrait2x3: '23',
  Portrait3x4: '34',
  Square1x1: 'square',
  Landscape4x3: '43',
  Landscape16x9: '169',
  Landscape16x8: '168',
  Landscape16x6: '166',
  Landscape23x6: '236',
} as const;
export type MediaAspectRatio = ValueOf<typeof MediaAspectRatio>;

export const MediaFooterPosition = {
  /** Under the media. */
  Default: 'default',
  /** On the right of the media on xs screen, under the media on larger screens. */
  Responsive: 'responsive',
  /** On the right of the media. */
  Right: 'right',
} as const;
export type MediaFooterPosition = ValueOf<typeof MediaFooterPosition>;

export type MediaProps = {
  /**
   * Sets a fixed CSS `width` to Media.
   *
   * Uses canonical CSS syntax.
   *
   * Defaults to `100%` when no `aspectRatio` is set, else `auto`.
   */
  width?: string | number;
  /**
   * Aspect ratio to force the Media `image` / `video` to.
   */
  aspectRatio?: MediaAspectRatio;
  /**
   * Sets a fixed CSS `height` to Media's `image` / `video` container.
   *
   * Uses canonical CSS syntax.
   *
   * Defaults to `100%` when no `aspectRatio` is set, else `auto`.
   */
  heightAsset?: string | number;
  /**
   * Takes a `MediaImage` component.
   *
   * Displays an image.
   */
  image?: ReactElement<HTMLAttributes<HTMLElement>>;
  /**
   * Takes a `MediaVideo` component.
   *
   * Displays a video.
   */
  video?: ReactElement;
  /**
   * Takes a `MediaOverlay` component.
   *
   * Displays a status and editorial information overlay above the Media `image` / `video`.
   */
  overlay?: ReactElement;
  /**
   * Takes a `MediaFooter` component.
   *
   *  Displays editorial information below the Media `image` / `video`.
   */
  footer?: ReactElement;
  /** ID of the component wrapper. */
  id?: HTMLAttributes<HTMLDivElement>['id'];
  /**
   * Whether to show the TV-specific focus styles when Media's actionable ancestor is focused.
   * @default true
   */
  showTvFocusStyles?: boolean;
  /**
   * The component's background color variant. Defaults to a solid grey color.
   * @default 'solid'
   */
  variant?: DiveBackgroundVariant;
  showPlaceholderAnim?: boolean;
  /**
   * The position of the footer.
   */
  footerPosition?: MediaFooterPosition;
} & DiveProps &
  AriaAttributes;
