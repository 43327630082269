import { OfferLocation } from '@dce-front/sdk-core';

/** To be used by `useLogout` */
export const getLogoutTargetUrl = (offerLocation: OfferLocation): string => {
  if (
    ([OfferLocation.fr, OfferLocation.it] as string[]).includes(offerLocation)
  ) {
    return window.location.origin;
  }

  if (window.location.pathname.includes('/ch/de')) {
    return `${window.location.origin}/ch/de`;
  }

  return `${window.location.origin}/${offerLocation}`;
};
