import {
  KEY_BACK,
  Layer,
  useActiveLayer,
  useKeyCatcher,
} from '@dce-front/one-navigation';
import { useBodyNoScroll } from '@dce-front/onewebapp-util-react';
import { Template } from '@dce-front/sdk-hodor';
import { useIsModalV2Open } from '@dce-front/sharedcomponent';
import classNames from 'classnames/bind';
import type { JSX, ReactElement } from 'react';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useEventListener } from 'usehooks-ts';
import constants from '../../constants/router';
import { useAppHistory, useAppLocation } from '../../helpers/hooks/reactRouter';
import { useFocusTrap } from '../../helpers/hooks/useFocusTrap';
import { LAYER_IMMERSIVE } from '../../helpers/oneNavigation/layers';
import { isFeatImmersiveFullAllowedSelector } from '../../store/slices/contextFeature-selectors';
import { hasFocusImmersiveSelector } from '../../store/slices/immersive-selectors';
import { isModalOpenSelector } from '../../store/slices/modal-selectors';
import { isPlayerOpenSelector } from '../../store/slices/player-selectors';
import { isEmbeddedVideoFullscreenOpenedSelector } from '../../store/slices/ui-selectors';
import styles from './Immersive.css';
import ImmersiveNav from './ImmersiveNav';
import { useCloseImmersive } from './useCloseImmersive';

export type ImmersiveProps = {
  children: ReactElement | null;
};

const FADE_ANIMATION_TIMEOUT = 100;
const cx = classNames.bind(styles);

/**
 * This container allows to open a content in an "immersive" mode.
 * Immersive mode is very similar with a modal,
 * but it has some CSS animations on opening & closing.
 *
 * @param children - node to render in immersive mode
 */
function Immersive({ children }: ImmersiveProps): JSX.Element {
  const history = useAppHistory();
  const location = useAppLocation();

  const hasFocus = useSelector(hasFocusImmersiveSelector);
  const isFeatImmersiveFullAllowed = useSelector(
    isFeatImmersiveFullAllowedSelector,
  );
  const isModalOpen = useSelector(isModalOpenSelector);
  const isMainPlayerOpen = useSelector(isPlayerOpenSelector);
  const isEmbeddedVideoFullscreenOpened = useSelector(
    isEmbeddedVideoFullscreenOpenedSelector,
  );
  const isModalV2Open = useIsModalV2Open({ enabled: !$_BUILD_RENDERMODE_CSR });
  const { immersive, page } = location.state || {};
  const { mainOnClick, context: immersiveContext } = immersive || {};
  const { context: pageContext } = page || {};

  const isSlideshow = mainOnClick?.displayTemplate === Template.Slideshow;
  const isDetailLight = mainOnClick?.displayTemplate === Template.DetailLight;
  const isCreativeMedia =
    mainOnClick?.displayTemplate === Template.CreativeMedia;
  const isDetail = !isSlideshow && !isDetailLight;

  const containerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [showFadeOutAnimation, setShowFadeOutAnimation] =
    useState<boolean>(false);

  const handleClose = useCloseImmersive();

  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      // Disable keydown listener when a modal is opened to avoid closing both the immersive when escaping modal
      if (
        $_BUILD_RENDERMODE_CSR ||
        isModalOpen ||
        isModalV2Open ||
        isEmbeddedVideoFullscreenOpened ||
        isMainPlayerOpen
      ) {
        return;
      }

      if (event.key === 'Escape') {
        event.stopPropagation();
        handleClose();
      }
    },
    [
      handleClose,
      isModalOpen,
      isModalV2Open,
      isEmbeddedVideoFullscreenOpened,
      isMainPlayerOpen,
    ],
  );

  useEventListener('keydown', handleKeydown);

  // Avoid scroll on body when immersive is open
  useBodyNoScroll({ suffix: 'immersive' });

  // Focus trap (Tab and Shift+Tab) inside the immersive
  useFocusTrap(containerRef, {
    enabled: !$_BUILD_RENDERMODE_CSR,
    lastElementIndex: isCreativeMedia ? -3 : undefined,
  });

  useKeyCatcher(KEY_BACK, handleClose, LAYER_IMMERSIVE);

  useEffect(() => {
    const onClickContext = immersiveContext || pageContext;

    // This condition is just checked when we are on the last immersive and we go back to the landing.
    if (!onClickContext || onClickContext !== 'immersive') {
      // Determine what should browser do after closing an immersive popin
      switch (history.action) {
        case constants.HISTORY_ACTION_POP:
        case constants.HISTORY_ACTION_PUSH:
          handleClose({ isBackNavigation: true });
          break;
        default:
          break;
      }
    }
  }, [handleClose, history.action, immersiveContext, location, pageContext]);

  useActiveLayer(LAYER_IMMERSIVE, $_BUILD_RENDERMODE_CSR);

  /**
   * Resets immersive fade out animation when :
   * - Opening of the immersive
   * - Loading new immersive content
   */
  useEffect(() => {
    setShowFadeOutAnimation(false);

    const fadeInAnimationId = setTimeout(() => {
      setShowFadeOutAnimation(true);
      if (wrapperRef.current) {
        wrapperRef.current.scrollTop = 0;
      }
    }, FADE_ANIMATION_TIMEOUT);

    return () => {
      clearTimeout(fadeInAnimationId);
    };
  }, [mainOnClick?.displayName]);

  useEffect(() => {
    if (wrapperRef.current) {
      const wrapper = wrapperRef.current;
      const scrollbarWidth = wrapper.offsetWidth - wrapper.clientWidth;
      document.documentElement.style.setProperty(
        '--scrollbar-width',
        `${scrollbarWidth}px`,
      );
    }
  }, []);

  return (
    <>
      {$_BUILD_RENDERMODE_CSR && (
        <div
          data-testid="backgroundTv"
          className={cx('immersive__backgroundTv')}
        />
      )}
      <dialog
        className={cx('immersive', {
          'immersive--isDefault': isDetail || isSlideshow,
        })}
        aria-modal="true"
        aria-labelledby="immersive-title"
        role="dialog"
      >
        <div
          ref={wrapperRef}
          className={cx('immersive__wrapper', {
            'immersive__wrapper--noScroll':
              isModalOpen || (!$_BUILD_RENDERMODE_CSR && !hasFocus),
            'immersive__wrapper--isSlideshow': isSlideshow,
          })}
          id="immersive"
          data-testid="immersive__wrapper"
        >
          <div
            className={cx('immersive__container', {
              'immersive__container--immersiveFull': isFeatImmersiveFullAllowed,
              'immersive__container--isDetailV5': isDetail,
              'immersive__container--isSlideshow': isSlideshow,
              'immersive__container--isDetailLight': isDetailLight,
            })}
            ref={containerRef}
            data-testid="immersive__container"
          >
            <div
              className={cx('immersive__container__backdrop')}
              onClick={() => handleClose()}
              data-testid="immersive__backdrop"
            />
            <div
              className={cx('immersive__container__overlay', {
                'immersive__container__overlay--fadeOut': showFadeOutAnimation,
              })}
            />
            <div className={cx('immersive__container__content')}>
              {!$_BUILD_RENDERMODE_CSR && (
                <ImmersiveNav
                  hasFocus={hasFocus}
                  isModalOpen={isModalOpen}
                  close={handleClose}
                  displayTemplate={mainOnClick?.displayTemplate}
                />
              )}
              <Layer layer={LAYER_IMMERSIVE}>{children}</Layer>
            </div>
          </div>
        </div>
      </dialog>
    </>
  );
}

Immersive.displayName = 'Immersive';

export default memo(Immersive);
