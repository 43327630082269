import loadable from '@loadable/component';
import Spinner from '../Spinner/Spinner';

const LoadableModal = loadable(
  () =>
    import('@dce-front/sharedcomponent/dist/components/Modal/Modal').then(
      (module) => module.Modal,
    ),
  {
    fallback: <Spinner />,
  },
);

export default LoadableModal;
