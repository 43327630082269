import { Template } from '@dce-front/sdk-hodor';
import { useSelector } from 'react-redux';
import { getDocumentHeaderMetasType } from '../../../helpers/metaTags/metaTags-helper';
import {
  hostnameSelector,
  offerLocationSelector,
} from '../../../store/slices/application-selectors';
import { useIsFrom } from '../../Page/useIsFrom';

/**
 * get hostname
 * @returns string
 */
export const useHostnameLink = () => {
  const offerLocation = useSelector(offerLocationSelector);
  const currentUrl = useSelector(hostnameSelector);
  const isFromDetail = useIsFrom(Template.DetailPage);

  const documentHeader = getDocumentHeaderMetasType(offerLocation);
  const defaultHostname =
    documentHeader?.app.head.defaultHostname ?? currentUrl;
  /**
   * Force mycanal in canonical url for all overlays if template is DETAIL_PAGE or DETAIL_SEASON
   */
  const hostname = isFromDetail ? defaultHostname : currentUrl;

  return hostname;
};
