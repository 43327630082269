export const PlayerPlaybackState = {
  Stopped: 'STOPPED',
  Loading: 'LOADING',
  Loaded: 'LOADED',
  Ended: 'ENDED',
  Playing: 'PLAYING',
  Paused: 'PAUSED',
  Buffering: 'BUFFERING',
  Seeking: 'SEEKING',
} as const;

export type PlayerPlaybackState =
  (typeof PlayerPlaybackState)[keyof typeof PlayerPlaybackState];
