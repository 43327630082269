export const ShowcaseTypes = {
  BANNER: 'banner',
  BUTTON: 'button',
  BRANDDISCOVER: 'brandDiscover',
  CHANNELSGRID: 'channelsGrid',
  CONTENTROW: 'contentRow',
  IMAGE: 'image',
  OFFERHIGHLIGHT: 'offerHighlight',
  PROGRAMSHIGHLIGHT: 'programsHighlight',
  PLAINTEXTHTML: 'plainTextHTML',
  REBOUNDPRODUCT: 'reboundProduct',
  SEPARATOR: 'separator',
  TABS: 'tabs',
  TEXT: 'text',
  TILES: 'tiles',
  WALL: 'wall',
} as const;

export type ShowcaseTypes = (typeof ShowcaseTypes)[keyof typeof ShowcaseTypes];

export const TextLayerTypes = {
  PARAGRAPH: 'paragraph',
  SUBTITLE: 'subtitle',
  TITLE: 'title',
  TITLE2: 'title2',
} as const;

export type TextLayerTypes =
  (typeof TextLayerTypes)[keyof typeof TextLayerTypes];

export const ButtonLayerTypes = {
  INLINE: 'inline',
  PLAIN: 'plain',
  PRIMARY: 'primary',
} as const;

export type ButtonLayerTypes =
  (typeof ButtonLayerTypes)[keyof typeof ButtonLayerTypes];
