import type {
  ApiV2DetailActionLayout,
  ApiV2DetailV5,
} from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import type { PrimaryActionOnClick } from '@dce-front/hodor-types/modules/action_layout/primary/definitions';

export const ActionType = {
  SET_ACTION_LAYOUT: 'SET_ACTION_LAYOUT',
  SET_DETAIL_PERSO: 'SET_DETAIL_PERSO',
  SET_URL_ACTION_LAYOUT: 'SET_URL_ACTION_LAYOUT',
  SET_NEXT_URL_ACTION_LAYOUT: 'SET_NEXT_URL_ACTION_LAYOUT',
  SET_DETAIL_DATA: 'SET_DETAIL_DATA',
  SET_PRIMARY_ACTION_CONTENT_ID: 'SET_PRIMARY_ACTION_CONTENT_ID',
  SET_IS_FUNNEL_TVOD_OPENED: 'SET_IS_FUNNEL_TVOD_OPENED',
} as const;

export type ActionType = (typeof ActionType)[keyof typeof ActionType];

type ActionSetActionLayout = {
  type: typeof ActionType.SET_ACTION_LAYOUT;
  payload: { actionLayout: ApiV2DetailActionLayout };
};
export const setActionLayout = (
  actionLayout: ApiV2DetailActionLayout,
): ActionSetActionLayout => ({
  type: ActionType.SET_ACTION_LAYOUT,
  payload: { actionLayout },
});

type ActionSetDetail = {
  type: typeof ActionType.SET_DETAIL_DATA;
  payload: ApiV2DetailV5;
};
export const setDetailData = (
  apiV2DetailV5: ApiV2DetailV5,
): ActionSetDetail => ({
  type: ActionType.SET_DETAIL_DATA,
  payload: apiV2DetailV5,
});

type ActionSetDetailPerso = {
  type: typeof ActionType.SET_DETAIL_PERSO;
  payload: { detail: ApiV2DetailV5['detail'] };
};
export const setDetailPerso = (
  detail: ApiV2DetailV5['detail'],
): ActionSetDetailPerso => ({
  type: ActionType.SET_DETAIL_PERSO,
  payload: { detail },
});

type ActionSetIsFunnelTvodOpened = {
  type: typeof ActionType.SET_IS_FUNNEL_TVOD_OPENED;
  payload: { isOpened: boolean };
};
export const setIsFunnelTvodOpened = (
  isOpened: boolean,
): ActionSetIsFunnelTvodOpened => ({
  type: ActionType.SET_IS_FUNNEL_TVOD_OPENED,
  payload: { isOpened },
});

type ActionSetPrimaryActionContentId = {
  type: typeof ActionType.SET_PRIMARY_ACTION_CONTENT_ID;
  payload: { primaryActionContentId: PrimaryActionOnClick['contentID'] };
};
export const setPrimaryActionContentId = (
  primaryActionContentId: PrimaryActionOnClick['contentID'],
): ActionSetPrimaryActionContentId => ({
  type: ActionType.SET_PRIMARY_ACTION_CONTENT_ID,
  payload: { primaryActionContentId },
});

type ActionSetNextURLActionLayout = {
  type: typeof ActionType.SET_NEXT_URL_ACTION_LAYOUT;
  payload: { nextURLActionLayout: ApiV2DetailActionLayout['URLActionLayout'] };
};
export const setNextURLActionLayout = (
  nextURLActionLayout: ApiV2DetailActionLayout['URLActionLayout'],
): ActionSetNextURLActionLayout => ({
  type: ActionType.SET_NEXT_URL_ACTION_LAYOUT,
  payload: { nextURLActionLayout },
});

type ActionSetURLActionLayout = {
  type: typeof ActionType.SET_URL_ACTION_LAYOUT;
  payload: { URLActionLayout: ApiV2DetailActionLayout['URLActionLayout'] };
};
export const setURLActionLayout = (
  URLActionLayout: ApiV2DetailActionLayout['URLActionLayout'],
): ActionSetURLActionLayout => ({
  type: ActionType.SET_URL_ACTION_LAYOUT,
  payload: { URLActionLayout },
});

export type DetailV5Action =
  | ActionSetActionLayout
  | ActionSetDetail
  | ActionSetDetailPerso
  | ActionSetIsFunnelTvodOpened
  | ActionSetNextURLActionLayout
  | ActionSetPrimaryActionContentId
  | ActionSetURLActionLayout;
