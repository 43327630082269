import type { AcmErrorCode } from '@dce-front/types-acm';

export class AcmError extends Error {
  constructor(
    errorCode: AcmErrorCode,
    errorDescription: string,
    statusCode: number,
  ) {
    super();

    Object.setPrototypeOf(this, AcmError.prototype);

    this.name = 'AcmError';
    this.errorCode = errorCode;
    this.errorDescription = errorDescription;
    this.statusCode = statusCode;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AcmError);
    }
  }

  errorCode: AcmErrorCode;

  errorDescription: string;

  statusCode: number;

  isOk: boolean = false;
}
