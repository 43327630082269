import type { ApiV2WsFromPath } from '@dce-front/hodor-types';
import { addQueryParam } from '@dce-front/onewebapp-utils';
import { Template } from '@dce-front/sdk-hodor';
import {
  RESULTS_PER_PAGE_CONTENTGRID,
  RESULTS_PER_PAGE_LANDING,
  RESULTS_PER_PAGE_LANDING_TV,
} from '../../../constants/hodor';
import { isDiscoverMode } from '../../../helpers/user/user-helper';

/**
 * Add some queries to the URLPage, depending on:
 * - the displayTemplate
 * - the discoverMode
 */
export const getUrlPageWithQueries = (
  wsFromPathResponse: ApiV2WsFromPath,
  subscriberId: number,
): string | undefined => {
  const { displayTemplate, URLPage } = wsFromPathResponse.redirectTo;
  const discoverMode = isDiscoverMode(subscriberId);

  if (!URLPage) {
    return;
  }

  let url = URLPage;

  // Add discoverMode queryParam in the URLPage in redirectTo when in discoverMode, but also for every contentGrid
  // accessed with wsFromPath (SSR first rendered page without onClick from hodor). We force discoverMode to true
  // for every contentGrid display template in order to be able to show all non-subscribed content (e.g. Paramount+
  // for a user who is not subscribed to the offer.
  // On SSR https://www.canalplus.com/originals/h/creplay_chaine_paramountplus_menu_originals, shouldn't be empty
  // for an user who is not subscribed to Paramount+
  if (displayTemplate === Template.ContentGrid || discoverMode) {
    url = addQueryParam(url, 'discoverMode', 'true');
  }

  if (displayTemplate === Template.ContentGrid && !discoverMode) {
    url = addQueryParam(url, 'get', RESULTS_PER_PAGE_CONTENTGRID);
  }

  if (displayTemplate === Template.Landing) {
    url = addQueryParam(
      url,
      'get',
      $_BUILD_RENDERMODE_CSR
        ? RESULTS_PER_PAGE_LANDING_TV
        : RESULTS_PER_PAGE_LANDING,
    );
  }

  return url;
};
