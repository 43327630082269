import {
  getProfile,
  setAdData,
  type OneCoreProfile,
} from '@canalplus/ifc-onecore';
import { fillTemplate } from '@dce-front/onewebapp-utils';
import { getPublicConfig } from '../../../helpers/config/config-helper';
import Logger from '../../../helpers/logger/logger-helper';
import { setTargetedAds } from '../../../store/slices/user';

export const processDidomiConsent = async ({
  adToken,
  noticeId,
  dispatch,
}: {
  adToken: string;
  noticeId?: string;
  dispatch: Redux.Dispatch;
}) => {
  const userProfile: OneCoreProfile = await getProfile();
  const consentString = localStorage.getItem('euconsent-v2');

  if (consentString) {
    const targetedAds = userProfile?.trackingPub ? '1' : '0';
    setAdData({
      gdpr: 1,
      targetedAds,
      ...(adToken && { targeting: adToken }),
      ...(consentString && { consentString }),
    });

    dispatch(setTargetedAds(targetedAds));
  }

  if (
    userProfile?.isAuthenticated &&
    (userProfile.askForConsent || !localStorage.getItem('euconsent-v2'))
  ) {
    handleConsentRedirection({ noticeId });
  }
};

export const handleConsentRedirection = ({
  noticeId,
}: {
  noticeId?: string;
}) => {
  const publicConfig = getPublicConfig();
  const didomiURL = publicConfig?.DIDOMI?.DIDOMI_STANDALONE_URL;
  const publicKey = publicConfig?.DIDOMI?.API_PUBLIC_KEY;
  const redirectUri = `${window.location.origin}${window.location.pathname}`;

  if (!didomiURL || !noticeId || !publicKey) {
    Logger.error('[RedirectionHandler] Didomi URL is not defined.');
    return;
  }

  const didomiRedirectURL = fillTemplate(didomiURL, [
    ['noticeId', noticeId],
    ['publicKey', publicKey],
    ['redirectUri', redirectUri],
  ]);

  Logger.info(
    `[RedirectionHandler] Redirecting to Didomi standalone page: ${didomiRedirectURL}`,
  );
  window.location.href = didomiRedirectURL;
};
