import type { MediaImageSource } from '../../components/Media/MediaImage/MediaImage.types';
import type { DiveViewportsWithDevice } from '../../types/Dive.types';
import { getThumborUrl } from '../getThumborUrl/getThumborUrl';
import type {
  DevicePixelRatio,
  DeviceToImageUrl,
  ThumborSources,
  ThumborSourcesDimensions,
} from '../thumbor.types';
import { isDeviceToViewportToImageDimensions } from '../thumbor.types';

export type GetThumborSourcesParameter = {
  /**
   * Dimensions object containing either one of:
   * - `ViewportToDimensions`
   * - `DeviceToViewportToDimensions`
   *
   * @example
   * // ViewportToDimensions
   * {
   *   XLarge?: Dimensions;
   *   large?: Dimensions;
   *   medium?: Dimensions;
   *   //...
   * }
   *
   * @example
   * // DeviceToViewportToDimensions
   *  {
   *    default?: ViewportToDimensions;
   *    tablet?: ViewportToDimensions;
   *    laptop?: ViewportToDimensions;
   *    mobile?: ViewportToDimensions;
   *  }
   */
  dimensions: ThumborSourcesDimensions;
  /**
   * Object containing a `DiveDevice` key pointing a device-specific `URL` value.
   *
   * @example
   * {
   *   default: 'https://cdn.images.com/DESKTOP_dimensions.jpg',
   *   mobile: 'https://cdn.images.com/MOBILE_dimensions.jpg',
   *   tablet: 'https://cdn.images.com/TABLET_dimensions.jpg',
   * }
   */
  urls: DeviceToImageUrl;
  /**
   * A number between `0` and `100`.
   *
   * Used by Thumbor to set the compression amount of served `webp`/`jpeg` images.
   *
   * @default 90 (server configuration)
   *
   * @see {@link https://thumbor.readthedocs.io/en/latest/quality.html Thumbor docs - quality}
   */
  quality?: number;
  /**
   * Ratio of the resolution in physical pixels to the resolution in CSS pixels for the current display device.
   *
   * Used to request images with Retina / HiDPI `dimensions`.
   */
  devicePixelRatio?: number;
  /**
   * Array of URL/domains found in URLs from `DeviceToImageUrl` that already includes an `ALLOWED_SOURCE` as per the server's [HTTP loader configuration](https://gitlab.canalplus.pro/deddev/thumbor/-/blob/master/infra/local/thumbor/conf/thumbor-http.conf).
   *
   * @see {@link https://thumbor.readthedocs.io/en/latest/image_loader.html#http-loader Thumbor docs - HTTP loader}
   
   */
  urlsWithAllowedSource?: string[] | readonly string[];
  /**
   * Array of objects containing key `viewport`, and optional keys `device` and `mediaQuery`,
   * needed to generate viewport-based `<source>` elements.
   *
   * @example
   * {
   *   { viewport: 'XSmall', device: 'mobile', mediaQuery: '(max-width: 419px)' },
   *   { viewport: 'small', device: 'mobile', mediaQuery: '(max-width: 699px)' },
   *   { viewport: 'medium', device: 'tablet', mediaQuery: '(max-width: 769px)' },
   *   { viewport: 'large', device: 'laptop', mediaQuery: '(max-width: 1440px)' },
   *   { viewport: 'XLarge', mediaQuery: '(min-width: 1441px)' },
   * }
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/source#using_the_media_attribute_with_picture MDN docs - media}
   */
  viewports: DiveViewportsWithDevice;
  includesAllowedSourceInUrl?: boolean;
};

/**
 * Returns an array of objects containing keys `srcset` and `media` to populate `<source>` HTML elements.
 *
 *  URL that is ready to be requested to the Thumbor server HTTP loader.
 *
 * 1. Iterate over each `viewports` and `device` combination.
 * 2. For each combination, get the `url` from `urls` object.
 * 3. Send the `url` to `getThumborUrl`.
 * 4. Populate:
 *    - the `srcset` with the relevant `url` and `urlRetina.
 *    - the `media` key with the relevant `media` query from `viewportsDeviceQuery`.
 *
 * **Important:**
 * - `urls` should match one of the `ALLOWED_SOURCE` in the server's HTTP loader configuration.
 * - The format of served images defaults to `WebP` and fallbacks to `JPEG`,\
 * based on whether the request was sent with an `Accept: image/webp` header.
 *
 * @see {@link https://canal-wiki.canal-plus.com/display/PE/Thumbor Thumbor server docs}
 * @see {@link https://gitlab.canalplus.pro/deddev/thumbor/-/blob/master/infra/local/thumbor/conf/thumbor-http.conf Thumbor server configuration}
 * @see {@link https://thumbor.readthedocs.io/en/latest/image_loader.html#http-loader Thumbor docs - HTTP loader}
 */
export function getThumborSources({
  urls,
  urlsWithAllowedSource,
  includesAllowedSourceInUrl,
  dimensions,
  devicePixelRatio,
  quality,
  viewports,
}: GetThumborSourcesParameter): ThumborSources | undefined {
  if (!Object.keys(urls).length) {
    return undefined;
  }

  return viewports.reduce<MediaImageSource[]>(
    (acc, { viewport, device, mediaQuery }) => {
      const url = (device && urls[device]) || urls.default;

      if (!url) {
        return acc;
      }

      const thumbor = getThumborUrl({
        quality,
        url,
        dimensions: isDeviceToViewportToImageDimensions(dimensions)
          ? (device && dimensions[device]?.[viewport]) ||
            dimensions?.default?.[viewport]
          : dimensions[viewport],
        devicePixelRatio: devicePixelRatio as DevicePixelRatio,
        includesAllowedSourceInUrl:
          includesAllowedSourceInUrl ||
          !!urlsWithAllowedSource?.some((thumborAllowedSource) =>
            url.includes(thumborAllowedSource),
          ),
      });

      const source: MediaImageSource = {
        media: mediaQuery,
        srcset: [
          thumbor.url,
          ...(thumbor?.urlRetina
            ? [`${thumbor.urlRetina} ${devicePixelRatio}x`]
            : []),
        ],
      };

      return [...acc, source];
    },
    [],
  );
}
