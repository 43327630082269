import { isSomeEnum } from '@dce-front/onewebapp-utils';
import { PersoLists, Template as TemplateEnum } from '@dce-front/sdk-hodor';
import type { JSX } from 'react';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useBinderMiddleware } from '../../helpers/hooks/useBinderMiddleware';
import Logger from '../../helpers/logger/logger-helper';
import { FocusManager } from '../../helpers/oneNavigation/FocusManager';
import { getMiddlewareContentGridTop } from '../../helpers/oneNavigation/middleware';
import {
  errorTemplateTextSelector,
  errorTemplateTitleSelector,
} from '../../store/slices/error-selectors';
import LoadableContentGrid from '../../templates/ContentGrid';
import LoadableContentGridPerso from '../../templates/ContentGridPerso';
import LoadableCreativeMediaConnected from '../../templates/CreativeMedia';
import LoadableDetailLight from '../../templates/DetailLight';
import LoadableDetailV5 from '../../templates/DetailV5';
import LoadableError from '../../templates/Error';
import LoadableFunnelTvod from '../../templates/FunnelTvod/components/LoadableFunnelTvod';
import type { FunnelHodorStep } from '../../templates/FunnelTvod/stores/constants';
import LoadableGabaritList from '../../templates/GabaritList';
import LoadableGeneralConditions from '../../templates/GeneralConditions';
import LoadableLandingV5 from '../../templates/LandingV5';
import LoadableMosaic from '../../templates/Mosaic';
import LoadableMyAccountIdp from '../../templates/MyAccountIdp';
import LoadablePrivacyManager from '../../templates/PrivacyManager';
import LoadableSearchStandalone from '../../templates/SearchStandalone';
import LoadableSectionsList from '../../templates/SectionsList';
import LoadableSettings from '../../templates/Settings';
import LoadableShowcase from '../../templates/Showcase';
import LoadableSlideshow from '../../templates/Slideshow';
import LoadableStub from '../../templates/Stub';
import LoadableTextBrut from '../../templates/TextBrut';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';

export type TemplateProps = {
  animationEnded?: boolean;
  focusManager?: FocusManager;
} & Pick<
  Routing.IOneDiscoveryOnClick,
  'displayName' | 'displayTemplate' | 'perso' | 'URLPage' | 'parameters'
>;

/** Switch between displayTemplate */
function Template({
  displayName = '',
  focusManager,
  perso,
  parameters,
  URLPage = '',
  displayTemplate,
}: TemplateProps): JSX.Element | null {
  const errorTemplateText = useSelector(errorTemplateTextSelector);
  const errorTemplateTitle = useSelector(errorTemplateTitleSelector);

  const onError = useCallback((): void => {
    Logger.error(
      `An error has occurred during while rendering the ${displayTemplate} template`,
    );
  }, [displayTemplate]);

  const middlewareContentGridTop = useBinderMiddleware(
    getMiddlewareContentGridTop,
  );

  // `displayTemplate` is empty until we receive the hodor response
  if (!displayTemplate) {
    return null;
  }

  const commonProps = {
    isMainTemplate: true,
    onClickParameters: parameters,
    url: URLPage,
  } satisfies {
    isMainTemplate: boolean;
    url: Routing.IOneDiscoveryOnClick['URLPage'];
    onClickParameters: Routing.IOneDiscoveryOnClick['parameters'];
  };

  const persoType = isSomeEnum(PersoLists)(perso) ? perso : undefined;

  const renderTemplate = (): JSX.Element => {
    switch (displayTemplate) {
      case TemplateEnum.ContentGrid:
        if (persoType) {
          return (
            <LoadableContentGridPerso
              header={{ title: displayName }}
              focusManager={focusManager}
              persoType={persoType}
              {...commonProps}
            />
          );
        }
        return (
          <LoadableContentGrid
            header={{ title: displayName }}
            focusManager={focusManager}
            middleware={middlewareContentGridTop}
            isVirtualization
            {...commonProps}
          />
        );
      case TemplateEnum.Mosaic:
        return <LoadableMosaic nbPlaceholderItems={20} {...commonProps} />;

      case TemplateEnum.DetailLight:
        return (
          <LoadableDetailLight {...commonProps} focusManager={focusManager} />
        );

      case TemplateEnum.DetailSeason:
      case TemplateEnum.DetailShow:
      case TemplateEnum.DetailPage:
      case TemplateEnum.Quicktime:
        return (
          <LoadableDetailV5 focusManager={focusManager} {...commonProps} />
        );

      case TemplateEnum.PaymentMeans:
      case TemplateEnum.ContextualOfferPage: {
        return (
          <LoadableFunnelTvod
            hodorStep={displayTemplate as FunnelHodorStep}
            url={URLPage}
            fullPage
          />
        );
      }

      case TemplateEnum.CreativeMedia:
        return <LoadableCreativeMediaConnected {...commonProps} />;

      case TemplateEnum.GabaritList:
        return (
          <LoadableGabaritList focusManager={focusManager} {...commonProps} />
        );

      case TemplateEnum.GeneralConditions:
        return <LoadableGeneralConditions {...commonProps} />;

      case TemplateEnum.Error:
        return (
          <LoadableError
            url={URLPage}
            title={errorTemplateTitle}
            text={errorTemplateText}
          />
        );

      case TemplateEnum.Landing:
        return (
          <LoadableLandingV5 focusManager={focusManager} {...commonProps} />
        );

      case TemplateEnum.TextBrut:
        return <LoadableTextBrut {...commonProps} />;

      case TemplateEnum.Settings:
        return <LoadableSettings {...commonProps} />;

      case TemplateEnum.MyAccountIdp:
        return (
          <LoadableMyAccountIdp focusManager={focusManager} {...commonProps} />
        );

      case TemplateEnum.Showcase:
        return (
          <LoadableShowcase focusManager={focusManager} {...commonProps} />
        );

      case TemplateEnum.SectionsList:
        return (
          <LoadableSectionsList focusManager={focusManager} {...commonProps} />
        );

      case TemplateEnum.Search:
        return (
          <LoadableSearchStandalone
            focusManager={focusManager}
            {...commonProps}
          />
        );

      case TemplateEnum.Slideshow:
        return (
          <LoadableSlideshow focusManager={focusManager} {...commonProps} />
        );

      case TemplateEnum.Stub:
        return <LoadableStub focusManager={focusManager} {...commonProps} />;

      case TemplateEnum.PrivacyManager:
        return (
          <LoadablePrivacyManager
            focusManager={focusManager}
            {...commonProps}
          />
        );

      default:
        return <LoadableError />;
    }
  };

  return <ErrorBoundary onError={onError}>{renderTemplate()}</ErrorBoundary>;
}

export default memo(Template);
