import { Template } from '@dce-front/sdk-hodor';
import { forwardRef, type ReactNode } from 'react';
import { ButtonShapes } from '../../constants/button';
import { StrateMode } from '../../constants/strates';
import { useInvariantSelector } from '../../helpers/hooks/useInvariantSelector';
import { getFeatureToggleFocusRestoration } from '../../store/slices/application-selectors';
import Button from '../Button/Button';
import { useLinkerContext } from './LinkerContext';
import type { LinkerProps } from './types';

/**
 * Based on given `data`, generates one of the following:
 * - myCanal `Button` component
 * - `<a>`
 * - `<button>`
 * - or custom element with 'renderWrapper'
 *
 * with onClick, href and target values
 * @example
    <Linker
      data={{ mainOnClick: button.onClick }}
      onClick={onClose}
      title={button.displayName}
    >
      {button.displayName}
    </Linker>

    Or use render custom :

    <Linker
      data={{ mainOnClick: button.onClick }}
      onClick={onClose}
      renderWrapper={(linkerSettings: LinkerSettings, children?: ReactNode) => (
          linkerSettings.href ?
              <a href={linkerSettings.href} target={linkerSettings.target} onClick={linkerSettings.onClick}>
                {children}
              </a>
              :
              <button type="button" onClick={linkerSettings.onClick} className={styles.defaultTemplate_button}>
                {children}
              </button>
      )}
    >
      {button.displayName}
    </Linker>
*/
const Linker = forwardRef<HTMLElement, LinkerProps>(function Linker(
  props: LinkerProps,
  ref,
): ReactNode {
  const {
    altText,
    altImage,
    ariaLabel,
    ariaLabelledBy,
    children,
    className,
    data,
    disabled = false,
    id,
    objKey = 'onClick',
    onClick,
    renderWrapper,
    replace,
    target = '_blank',
    title,
    ...rest
  } = props;
  const featFocusRestoration = useInvariantSelector(
    getFeatureToggleFocusRestoration,
  );

  const { isInit, getLinkerSettings } = useLinkerContext();

  if (!isInit || !getLinkerSettings) {
    return children;
  }

  // Disable immersive when featFocusRestoration is enabled by removing context from data
  const linkerSettings = getLinkerSettings({
    data: {
      ...data,
      context:
        featFocusRestoration && data?.context === 'immersive'
          ? undefined
          : data?.context,
    },
    objKey,
    target,
    onClick,
    replace,
  });

  // case to render custom element with renderWrapper function
  if (renderWrapper) {
    return renderWrapper(linkerSettings, children);
  }

  const linkerDataDisplayTemplate = data?.mainOnClick?.displayTemplate;

  // mostly used on detailV5 primary action button / episodeList
  if (
    linkerDataDisplayTemplate === Template.Player &&
    objKey !== StrateMode.LiveTv
  ) {
    return (
      <Button
        buttonRef={ref as React.RefObject<HTMLButtonElement>}
        id={id}
        shape={ButtonShapes.ROUND}
        className={className}
        text={title}
        isV5Style={$_BUILD_RENDERMODE_CSR}
        handler={linkerSettings.onClick}
        isDisabled={disabled}
        ariaLabel={ariaLabel}
      />
    );
  }

  // case react-router `Link` component
  if (linkerSettings.href) {
    // Not use Link from router on tv for best performances
    return (
      <a
        aria-disabled={disabled}
        aria-label={ariaLabel || altText || altImage || title}
        aria-labelledby={ariaLabelledBy}
        className={className}
        id={id}
        onClick={linkerSettings.onClick}
        ref={ref as React.RefObject<HTMLAnchorElement>}
        target={linkerSettings.target}
        title={title}
        {...(!disabled ? { href: linkerSettings.href } : {})}
        {...rest}
      >
        {children}
      </a>
    );
  }

  // case HTML button
  return (
    <button
      ref={ref as React.RefObject<HTMLButtonElement>}
      type="button"
      id={id}
      onClick={linkerSettings.onClick}
      className={className}
      aria-disabled={disabled}
      disabled={disabled}
    >
      {children}
    </button>
  );
});

export default Linker;
