import type { Key } from '../constants/keys';
import { EventEmitter } from '../utils/EventEmitter';
import type { StoreBinder } from './StoreBinder';
import { StoreLayer } from './StoreLayer';
import type {
  BackHandler,
  EnterHandler,
  FocusChangeHandler,
  IElement,
  IStore,
  LayerMode,
} from './types';

export type StoreOptions = {
  onFocusChange?: FocusChangeHandler;
  onEnter?: EnterHandler;
  onBack?: BackHandler;
  selector?: string;
  layerMode?: LayerMode;
  enabled?: boolean;
};

const DEFAULT_SELECTOR = 'a[href], button:enabled';

export class Store extends EventEmitter<Key> implements IStore {
  /**
   * Indicates whether the store is enabled
   *
   * @default true
   * @public
   */
  enabled: boolean = true;

  /**
   * Element used when `focusDefault()` is called. Positioned at x=0, y=-100000, width=1, height=1
   *
   * @package
   */
  defaultElement: IElement = {
    getBoundingClientRect: /* istanbul ignore next */ () =>
      new DOMRect(0, -100000, 1, 1),
  };

  /**
   * Dict of currently registered layers
   *
   * @private
   */
  layers: Record<number, StoreLayer> = {};

  /**
   * Index of currently active layer
   *
   * @private
   */
  activeLayer: number = 0;

  /**
   * Allow user to submit a callback that is invoked each time focus changes with previous and
   * next element
   *
   * @public
   */
  onFocusChange?: FocusChangeHandler;

  /**
   * Callback invoked when Enter key is pressed on a focused element
   *
   * @public
   */
  onEnter?: EnterHandler;

  /**
   * Callback invoked when Back key is pressed on a focused element
   *
   * @public
   */
  onBack?: BackHandler;

  /**
   * Default selector used if none is provided in <Binder />
   *
   * @public
   */
  selector: string;

  /**
   * Layer mode of one-navigation
   *
   * @public
   * @readonly
   */
  layerMode: LayerMode;

  constructor(options: StoreOptions = {}) {
    super();

    this.onFocusChange = options.onFocusChange;
    this.onEnter = options.onEnter;
    this.onBack = options.onBack;
    this.selector = options.selector || DEFAULT_SELECTOR;
    this.layerMode = options.layerMode || 'free';
    this.enabled = options.enabled !== false;
  }

  setOnFocusChange(onFocusChange?: FocusChangeHandler): void {
    this.onFocusChange = onFocusChange;
  }

  setOnEnter(onEnter?: EnterHandler): void {
    this.onEnter = onEnter;
  }

  setOnBack(onBack?: BackHandler): void {
    this.onBack = onBack;
  }

  /**
   * Convenient getter for `store.getActiveLayer().current`
   *
   * @public
   */
  get current(): HTMLElement | undefined {
    return this.getActiveLayer().current;
  }

  /**
   * Convenient getter for `store.getActiveLayer().currentBinder`
   *
   * @public
   */
  get currentBinder(): StoreBinder | undefined {
    return this.getActiveLayer().currentBinder;
  }

  /**
   * Retrieve a layer by id. Creates it if needed.
   *
   * @public
   *
   * @param id Id of the layer to create
   */
  getLayer(id: number): StoreLayer {
    if (!this.layers[id]) {
      this.layers[id] = new StoreLayer(this, id);
    }

    return this.layers[id];
  }

  /**
   * Retrieves the currently active layer
   *
   * @public
   */
  getActiveLayer(): StoreLayer {
    return this.getLayer(this.activeLayer);
  }

  /**
   * Retrieves the layer with the highest id and at least one enabled binder
   *
   * @param maxLayerId skip layers with id below `maxLayerId`. Defaults to `Infinity`
   * @returns the highest layer with one enabled binder or the lowest layer if none is found
   */
  getHighestActiveLayer(maxLayerId: number = Infinity): StoreLayer | undefined {
    let current: StoreLayer | undefined;
    // Lowest layer without enabled binders to fallback to
    let lowest: StoreLayer | undefined;

    const layers = Object.values(this.layers);

    for (let index = layers.length - 1; index > -1; index -= 1) {
      const layer = layers[index];
      const layerId = layer?.id || 0;

      if (!lowest || lowest.id > layerId) {
        lowest = layer;
      }

      if (
        layerId > maxLayerId ||
        layer?.getEnabledBinders().length === 0 ||
        (current && current.id > layerId)
      ) {
        continue;
      }

      current = layer;
    }

    return current || lowest;
  }

  /**
   * Sets the currently active layer. If passed id is already the current layer, this method is a
   * no-op
   *
   * @public
   *
   * @param id id of layer to set active
   */
  setActiveLayer(id: number): void {
    if (this.activeLayer === id) {
      return;
    }

    const previous = this.getActiveLayer().current;

    this.activeLayer = id;

    const next = this.getActiveLayer().current;

    if (this.onFocusChange) {
      this.onFocusChange(previous, next);
    }
  }

  /**
   * Enables or disables the store
   *
   * @public
   * @param {boolean} enabled - If true, the store is enabled; if false, it is disabled
   */
  setEnabled(enabled: boolean): void {
    this.enabled = enabled;
  }

  /**
   * Proxy for `store.getActiveLayer().focusDefault()`
   *
   * @public
   */
  focusDefault(): void {
    if (!this.enabled) {
      return;
    }
    this.getActiveLayer().focusDefault();
  }

  /**
   * Proxy for `store.getActiveLayer().handleKey(key)`
   *
   * @public
   *
   * @param key key to handle
   */
  handleKey(key: Key): void {
    if (!this.enabled) {
      return;
    }
    this.emit('key', key);
    this.getActiveLayer().handleKey(key);
  }
}
