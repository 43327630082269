import type { ApiV2PageRubrique } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/gabarit_list/definitions';
import { Template } from '@dce-front/sdk-hodor';

export const isLiveGridFavoritesRubric = (rubric: ApiV2PageRubrique): boolean =>
  rubric?.displayTemplate === Template.LiveGrid &&
  !!rubric?.displayOnlyFavoriteChannels;

export const removeLiveGridFavoritesRubric = (
  rubrics: ApiV2PageRubrique[] = [],
): ApiV2PageRubrique[] => {
  return rubrics.filter((rubric) => !isLiveGridFavoritesRubric(rubric));
};
