import type { MiddlewareFactory } from '@dce-front/one-navigation';
import { useMemo } from 'react';
import { getFeatureToggleNewTVNavigation } from '../../../store/slices/application-selectors';
import { useInvariantSelector } from '../useInvariantSelector';

export type BinderMiddlewareOptions = {
  itemOffset?: number;
};

export const useBinderMiddleware = (
  middleware: (
    featNewTVNavigation: boolean,
    options?: BinderMiddlewareOptions,
  ) => MiddlewareFactory[],
  options?: BinderMiddlewareOptions,
) => {
  const featNewTVNavigation = useInvariantSelector(
    getFeatureToggleNewTVNavigation,
  );

  return useMemo(() => {
    return middleware(featNewTVNavigation, { itemOffset: options?.itemOffset });
  }, [featNewTVNavigation, middleware, options?.itemOffset]);
};
