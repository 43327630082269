import { Template } from '@dce-front/sdk-hodor';
import { useContext } from 'react';
import { useIsFromDetail } from '../../templates/DetailV5/data/provider';
import { GabaritListContext } from '../../templates/GabaritList/components/GabaritListContext';
import { LandingContext } from '../../templates/LandingV5/components/LandingV5Context';
import { ShowcaseContext } from '../../templates/Showcase/components/ShowcaseContext';

export function useIsFrom(template: Template): boolean {
  const isFromDetail = useIsFromDetail();
  const isFromShowcase = useContext(ShowcaseContext);
  const isFromGabaritList = useContext(GabaritListContext);
  const isFromLanding = useContext(LandingContext);

  switch (template) {
    case Template.DetailPage:
    case Template.DetailShow:
    case Template.DetailSeason:
      return isFromDetail;

    case Template.Showcase:
      return isFromShowcase;

    case Template.GabaritList:
      return isFromGabaritList;

    case Template.Landing:
      return isFromLanding;

    default:
      return false;
  }
}
