import { AppMenuSearchSvg, ButtonIcon } from '@dce-front/dive';
import type { ApiV2SearchPage } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/search/definitions';
import type { NavigationInfoItem } from '@dce-front/hodor-types/modules/account_profile/definitions';
import { DATA_ATTR_HEADER_SEARCH_TRIGGER } from '@dce-front/sharedcomponent';
import type { JSX } from 'react';
import { cloneElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActionCreators } from '../../../helpers/hooks/useActionCreators';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { useQueryTemplate } from '../../../helpers/hooks/useQueryTemplate/useQueryTemplate';
import { useTranslation } from '../../../lang';
import { FetchRequestTypes } from '../../../services/types';
import { setSearchPageParameters } from '../../../store/slices/application';
import { setSearchActive } from '../../../store/slices/search';
import { isSearchActiveSelector } from '../../../store/slices/search-selectors';
import Linker from '../../Linker/Linker';
import type { LinkerSettings } from '../../Linker/types';
import { SKIP_LINK_ID } from '../../SkipLink/constants';

type HeaderSearchButtonOneDiscoveryProps = {
  /**
   * Whether or not the button is selected and represents current path.
   * @default false
   */
  isSelected?: boolean;
  /**
   * User menu button data.
   */
  data?: NavigationInfoItem;
};

/**
 * Header search button for OneDiscovery. It is capable of displaying a search content via deep linking.
 * It renders the search button with a custom icon.
 * @example
 *   <HeaderSearchButtonOneDiscovery
 *     data={item}
 *     isSelected={isActive}
 *   />
 */
export function HeaderSearchButtonOneDiscovery({
  isSelected = false,
  data,
}: HeaderSearchButtonOneDiscoveryProps): JSX.Element {
  const { onClick } = data || {};
  const dispatch = useAppDispatch();
  const isSearchActive = useSelector(isSearchActiveSelector);

  const [{ data: dataFetch }] = useQueryTemplate<ApiV2SearchPage>(
    onClick?.URLPage,
    {
      onClickParameters: onClick?.parameters,
      template: FetchRequestTypes.SearchStandalone,
      noTracking: true,
    },
    {
      enabled: isSearchActive,
    },
  );

  useEffect(() => {
    if (dataFetch?.pageParameters) {
      dispatch(setSearchPageParameters(dataFetch?.pageParameters));
    }
  }, [dataFetch?.pageParameters, dispatch]);

  const { t } = useTranslation();
  const { openSearch } = useActionCreators({ openSearch: setSearchActive });

  const searchComponent = (
    <ButtonIcon
      id={SKIP_LINK_ID.appHeaderSearch}
      aria-label={t('Search.activateSearchTriggerLabel')}
      icon={<AppMenuSearchSvg />}
      isSelected={isSelected}
      variant="header"
      {...(!$_BUILD_RENDERMODE_CSR && {
        onClick: () => openSearch(),
        [DATA_ATTR_HEADER_SEARCH_TRIGGER]: true, // Do not delete: this is used to measure the DOM when header search initiates
      })}
    />
  );

  return $_BUILD_RENDERMODE_CSR ? (
    <Linker
      data={onClick ? { mainOnClick: onClick } : undefined}
      renderWrapper={(linkerSettings: LinkerSettings) =>
        cloneElement(searchComponent, { onClick: linkerSettings.onClick })
      }
    />
  ) : (
    searchComponent
  );
}
