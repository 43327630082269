import type { ButtonHTMLAttributes } from 'react';
import type { DiveProps } from '../../types/Dive.types';
import type { ValueOf } from 'ts-essentials';

export const AlertStatus = {
  Error: 'error',
  Hint: 'hint',
  Info: 'info',
  Success: 'success',
  Warning: 'warning',
} as const;
export type AlertStatus = ValueOf<typeof AlertStatus>;

export const AlertVariant = {
  AlertBox: 'alertBox',
  InputFieldAlertStandalone: 'inputFieldAlertStandalone',
  InputFieldAlertAttached: 'inputFieldAlertAttached',
} as const;
export type AlertVariant = ValueOf<typeof AlertVariant>;

export type AlertIconsAriaLabels = Partial<
  Record<AlertStatus | 'close', string>
>;

export type AlertProps = {
  /**
   * The textual content of the alert to be displayed to the user.
   * This prop is required to convey the necessary information or notification
   * details to the user.
   */
  message: string;
  /**
   * If the alert can be manually closed by the user with close icon.
   *
   * Note: Requires 'variant='alertBox' to display the icon; otherwise, it has no effect.
   * @defaultValue false
   */
  closable?: boolean;
  /**
   * The status type of the alert, determining its thematic styling and icon.
   *
   * Acceptable values include "error", "hint", "info", "success", and "warning".
   * If omitted, "hint" status will be applied.
   *
   */
  status?: AlertStatus;
  /**
   * Custom ARIA labels for the statuses and close icon.
   * Defaults to ARIA labels in English.
   */
  iconsAriaLabels?: AlertIconsAriaLabels;
  /**
   * An optional title for the alert to display above the message.
   *
   */
  title?: string;
  /**
   * Specifies the visual variant of the alert, determining its structural styling.
   *
   * Possible values include "alertBox", "inputFieldAlertStandalone", and "inputFieldAlertAttached".
   * If omitted, "alertBox" variant will be applied.
   *
   */
  variant?: AlertVariant;
  /**
   * Specifies the label for CTA button
   *
   */
  buttonLabel?: string;
  /**
   * Custom ARIA label for CTA button
   * If omitted, `buttonLabel` will be applied.
   */
  buttonAriaLabel?: string;
  /**
   * Specifies the label for Ignore button
   * Default to `Ignore` for Tv device
   *
   */
  ignoreLabel?: string;
  /**
   * Callback function fired when CTA button has been clicked
   */
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  /**
   * Callback function fired when close button has been clicked
   */
  onClose?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  /**
   * Indicate whether the alert should be closed after the CTA button is clicked
   * @defaultValue true
   */
  closableOnClick?: boolean;
} & DiveProps;
