import type { HodorApiParameter } from '@dce-front/sdk-hodor';

/**
 * Hodor Api parameters used for a perso call
 * - **tokenPass**
 * - **xx-profile-id**
 */
export const PERSO_HODOR_API_PARAMETERS: HodorApiParameter[] = [
  { id: 'xx-profile-id', in: 'header' },
  { id: 'tokenPass', in: 'header' },
];

export const RESULTS_PER_PAGE_LANDING = '14';
export const RESULTS_PER_PAGE_LANDING_TV = '7';
export const RESULTS_PER_PAGE_CONTENTGRID = '50';
