import { OfferLocation } from '@dce-front/sdk-core';

type MyCanalTitlesMapping = {
  [key in OfferLocation]?: string;
};

export const myCanalTitlesMapping: MyCanalTitlesMapping = {
  [OfferLocation.bf]: 'myCANAL Burkina Faso',
  [OfferLocation.bi]: 'myCANAL Burundi',
  [OfferLocation.bj]: 'myCANAL Bénin',
  [OfferLocation.bl]: 'myCANAL Saint-Barthelemy',
  [OfferLocation.cd]: 'myCANAL RDC',
  [OfferLocation.cf]: 'myCANAL République Centrafricaine',
  [OfferLocation.cg]: 'myCANAL Congo',
  [OfferLocation.ch]: 'myCANAL Suisse',
  [OfferLocation.ci]: 'myCANAL Côte d’Ivoire',
  [OfferLocation.cm]: 'myCANAL Cameroun',
  [OfferLocation.cv]: 'myCANAL Cap Vert',
  [OfferLocation.dj]: 'myCANAL Djibouti',
  [OfferLocation.fr]: 'myCANAL',
  [OfferLocation.ga]: 'myCANAL Gabon',
  [OfferLocation.gf]: 'myCANAL Guyane',
  [OfferLocation.gh]: 'myCANAL Ghana',
  [OfferLocation.gm]: 'myCANAL Gambie',
  [OfferLocation.gn]: 'myCANAL Guinée',
  [OfferLocation.gp]: 'myCANAL Guadeloupe',
  [OfferLocation.gq]: 'myCANAL Guinée Équatoriale',
  [OfferLocation.gw]: 'myCANAL Guinée Bissau',
  [OfferLocation.it]: 'TIMVISION',
  [OfferLocation.km]: 'myCANAL Comores',
  [OfferLocation.mf]: 'myCANAL Saint-Martin',
  [OfferLocation.mg]: 'myCANAL Madagascar',
  [OfferLocation.ml]: 'myCANAL Mali',
  [OfferLocation.mq]: 'myCANAL Martinique',
  [OfferLocation.mr]: 'myCANAL Mauritanie',
  [OfferLocation.mu]: 'myCANAL Maurice',
  [OfferLocation.nc]: 'myCANAL Nouvelle-Calédonie',
  [OfferLocation.ne]: 'myCANAL Niger',
  [OfferLocation.ng]: 'myCANAL Nigeria',
  [OfferLocation.pl]: 'CANAL+',
  [OfferLocation.re]: 'myCANAL Réunion',
  [OfferLocation.rw]: 'myCANAL Rwanda',
  [OfferLocation.sl]: 'myCANAL Sierra Leone',
  [OfferLocation.sn]: 'myCANAL Sénégal',
  [OfferLocation.td]: 'myCANAL Tchad',
  [OfferLocation.tg]: 'myCANAL Togo',
  [OfferLocation.vu]: 'myCANAL Vanuatu',
  [OfferLocation.wf]: 'myCANAL Wallis Et Futuna',
  [OfferLocation.yt]: 'myCANAL Mayotte',
  [OfferLocation.pf]: 'myCANAL Polynésie Française',
};
