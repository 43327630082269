import type { Type } from '@dce-front/onewebapp-utils';

export type VirtualKeyboardState = {
  cursorIndex: number;
  inputValue: string[];
  mode: Mode;
};

export const ActionType = {
  ADD_CHAR: 'ADD_CHAR',
  DECREMENT: 'DECREMENT',
  DELETE_CHAR: 'DELETE_CHAR',
  INCREMENT: 'INCREMENT',
  RESET: 'RESET',
  SET_INPUT_VALUE: 'SET_INPUT_VALUE',
  UPDATE_MODE: 'UPDATE_MODE',
} as const;

export type ActionType = (typeof ActionType)[keyof typeof ActionType];

export type ActionAddCharacter = {
  type: typeof ActionType.ADD_CHAR;
  inputValue: string[];
};

export type ActionDecrement = { type: typeof ActionType.DECREMENT };

export type ActionDeleteCharacter = {
  type: typeof ActionType.DELETE_CHAR;
  inputValue: string[];
};

export type ActionIncrement = { type: typeof ActionType.INCREMENT };

export type ActionUpdateMode = {
  type: typeof ActionType.UPDATE_MODE;
  mode: Mode;
};

export type ActionReset = { type: typeof ActionType.RESET; mode: Mode };

export type ActionSetInputValue = {
  type: typeof ActionType.SET_INPUT_VALUE;
  inputValue: string[];
};

export type Action =
  | ActionAddCharacter
  | ActionDecrement
  | ActionDeleteCharacter
  | ActionIncrement
  | ActionUpdateMode
  | ActionSetInputValue;

export const Mode = {
  LOWERCASE: 'lowercase',
  UPPERCASE: 'uppercase',
  SPECIALS: 'specials',
} as const;

export type Mode = (typeof Mode)[keyof typeof Mode];

export type Key = {
  id: string;
  sign: string;
  special?: string;
  type?: Type;
};
